<template>
  <div class="card md-10">
    <Menubar :model="items">
      <template #start></template>
      <template #item="{ item, props, hasSubmenu, root }">
        <a v-ripple class="flex align-items-center" v-bind="props.action">
          <router-link to="/">
            <span :class="item.icon"/>
            <span class="ml-2">{{ item.label }}</span>
          </router-link>
          <Badge
            v-show="item.badge"
            :class="{ 'ml-auto': !root, 'ml-2': root }"
            :value="item.badge"
          />
          <span
            v-show="item.shortcut"
            class="ml-auto border-1 surface-border border-round surface-100 text-xs p-1"
          >{{ item.shortcut }}</span
          >
          <i
            v-show="hasSubmenu"
            :class="[
              'pi pi-angle-down',
              { 'pi-angle-down ml-2': root, 'pi-angle-right ml-auto': !root },
            ]"
          ></i>
        </a>
      </template>
      <template #end>
        <div class="flex align-items-center gap-2">
          <InputText
            v-model="searchQuery"
            class="w-8rem sm:w-auto"
            placeholder="Search"
            type="text"
          />
        </div>
      </template>
    </Menubar>
  </div>
</template>

<script>
import {ref, watch} from 'vue';

export default {
  setup() {
    const items = ref([
      {
        label: 'CheapLens',
        icon: 'pi pi-camera',
      },
    ]);

    const searchQuery = ref('');

    watch(searchQuery, (newQuery) => {
      if (newQuery.length >= 3) {
        handleSearch(newQuery);
      }
    });

    const handleSearch = (query) => {
      const trimmedQuery = query.slice(0, 150).toLowerCase();
      console.log('Search query:', trimmedQuery);
      // Add your search logic here, e.g., make an API call
    };

    return {items, searchQuery};
  },
  components: {},
};
</script>
<style>
a,
a:hover,
a:visited {
  color: #35495e; /* Default link color */
  text-decoration: none; /* Removes underline */
}
</style>>
