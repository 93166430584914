import {createApp} from 'vue';
import App from './App.vue';
import PrimeVue from 'primevue/config';
import {router} from './router/index.js';
import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import Ripple from 'primevue/ripple';
import * as Sentry from '@sentry/vue';
import {createHead, VueHeadMixin} from '@unhead/vue';
import HighchartsVue from "highcharts-vue";

const app = createApp(App);

Sentry.init({
  app,
  dsn: 'https://f22913dd78b3f4048ea29e9dcef87c3b@o4506220503564288.ingest.us.sentry.io/4506220522373120',
  integrations: [Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.05,
  replaysOnErrorSampleRate: 1.0,
});

app.use(PrimeVue, {ripple: true, inputStyle: 'filled'});
app.use(router);
app.use(HighchartsVue);

const head = createHead();
app.mixin(VueHeadMixin);
app.directive('ripple', Ripple);


// Lazy load SweetAlert2
app.config.globalProperties.$swal = () => import('sweetalert2').then(module => module.default);

app.mount('#app');
