import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  { path: '/', name: 'home', component: () => import('../pages/Landing.vue') },
  {
    path: '/lens/:slug',
    name: 'lensDetails',
    component: () => import('../pages/LensDetailPage.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'notFound',
    component: () => import('../pages/NotFoundPage.vue'),
  },
];

export const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return (
      savedPosition ||
      new Promise(resolve => {
        setTimeout(() => resolve({ top: 0 }), 300);
      })
    );
  },
});
