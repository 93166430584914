<template>
  <div class="card justify-content-center">
    <div class="p-col-12 p-md-6 p-lg-4 p-xl-4">
      <Navbar />
      <router-view v-slot="{ Component }" class="pt-2">
        <transition mode="out-in" name="fade">
          <component
            :is="Component"
            :key="$route.path + $route.query"
          ></component>
        </transition>
      </router-view>
    </div>
  </div>
</template>

<script>
import Navbar from './components/Navbar.vue';

export default {
  components: { Navbar },
};
</script>
<style lang="css">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
